import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body p-10 p-lg-15" }
const _hoisted_3 = { class: "pt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", null, [
        _createVNode("h2", null, "Build Fast & Modern Admin Dashboards with " + _toDisplayString(_ctx.themeName) + ".", 1),
        _createVNode("p", _hoisted_3, _toDisplayString(_ctx.themeName) + " - Bootstrap 5 Vue Multi-purpose Admin Dashboard Theme. ", 1)
      ])
    ])
  ]))
}